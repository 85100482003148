import { createContext, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './styles/styles.scss';
import WalletConnectProvider from '@walletconnect/web3-provider';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Web3 from 'web3';
import {
  contractabi2,
  contractAddress2,
  refDefaultAddress,
  contractabi1,
  contractAddress1,
  ACCESS_TOKEN,
  // tokenAddress, tokenabi
} from './constants/constants';
import Layout from './Components/Layout';
import Stakebnb from './Components/stakebnb/Stakebnb';
import Stakeeth from './Components/stakeeth/Stakeeth';
import Dashboard from './Components/Dashboard';
import Stake from './Components/Stake';
import HomePage from './Pages/HomePage';
import FaqPage from './Pages/FaqPage';
import { Toaster } from 'react-hot-toast';
import { getCookie } from './constants/utils';

export const ActiveContext = createContext();

const App = () => {
  const [active, setActive] = useState(1);
  const [accessToken, setAccessToken] = useState(getCookie(ACCESS_TOKEN));

  //const [active, setActive] = useState(1);
  const [value, setValue] = useState(20);
  const [nftprice, nftsetprice] = useState([]);
  const [nftitems, nftsetitems] = useState([]);
  const [mnftprice, mnftsetprice] = useState([]);
  const [mnftitems, mnftsetitems] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [mynfts, setMyNfts] = useState([]);
  const [listednfts, setListedNfts] = useState([]);
  const [loadingState, setLoadingState] = useState('not-loaded');
  const [walletvalue, setWalletValue] = useState('.......');
  const [balance, setBalance] = useState('token');
  const [ticket, setticketBalance] = useState('nft');
  const [inputval, setInputVal] = useState();
  var [fff, setfff] = useState([]);
  var [nnn, setnnn] = useState([]);
  const [account, setAccount] = useState('Metamask');
  const [Connect, setConnect] = useState('WalletConnect');
  const [connected, setconnected] = useState(false);
  const [connectWallet, setConnectWallet] = useState('WalletConnect');
  const [show, setShow] = useState(false);
  const [showmeta, setShowMeta] = useState(false);
  const [showwallet, setShowWallet] = useState(false);
  const [hi, sethin] = useState(false);
  const [supply, setsupply] = useState();
  const [ethersupply, setethersupply] = useState();
  const [address, setaddress] = useState('Connect Wallet');
  const [referral, setreferral] = useState(
    '0x2Ff10D4343f225AfcA34C39B03ffe270a393268D'
  );
  const [userstake, setuserstake] = useState(0);
  const [userearned, setuserearned] = useState(0);
  const [userbal, setuserbal] = useState(0);
  const [totalstaked, settotalstaked] = useState(0);
  const [amethyst, setamethyst] = useState();
  const [aquamarine, setaquamarine] = useState();
  const [garnet, setgarnet] = useState();
  const [blue, setblue] = useState();
  const [ruby, setruby] = useState();
  const [emerald, setemerald] = useState();
  const [showreferral, setshowreferral] = useState(false);
  const [bnbmyOnfts, setBnbMyONfts] = useState([])
  const [ethmyOnfts, setEthMyONfts] = useState([])
  const [bnbconnect, setbnbconnect] = useState(false)
  const [ethconnect, setethconnect] = useState(false)
  const [hideconnect, sethideconnect]= useState(true);
  const [hidenetwork, sethidenetwork]= useState(false);
  const [hidewalletnetwork, sethidewalletnetwork]= useState(false);
  const [bnbbalance, setbnbbalance]= useState();
  const [ethbalance, setethbalance]= useState();
  const [bscaddress, setbscaddress]= useState("Connect BNB");
  const [ethaddress, setethaddress]= useState("Connect ETH");
  const [bscaddressconnected, setbscaddressconnected]= useState(false);
  const [ethaddressconnected, setethaddressconnected]= useState(false);
  const [bscwalletaddress, setbscwalletaddress]= useState();
  const [ethwalletaddress, setethwalletaddress]= useState();
  const [mybnb, setmybnb]= useState([]);
  const [myeth, setmyeth]= useState([])
  const [bnbdisconnectbutton, setbnbdisconnectbutton]= useState(true);
  const [ethdisconnectbutton, setethdisconnectbutton]= useState(true);
  const [bnbdconnectbutton, setbnbconnectbutton]= useState(true);
  const [ethconnectbutton, setethconnectbutton]= useState(true);

  let getDirectFromUrl;

  let addresvalue;

  let accountAd;
  let item;

  const bnbconnectfunc =  () => {
    
    setbnbconnect(true)
    setethconnect(false)      
  }

  const ethconnectfunc =  () => {
    
    setethconnect(true)
    setbnbconnect(false)   
  }

  const ethWeb3 = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
        

        
      });
      setethaddress("Eth Connected");
      setbscaddress("Connect BNB"); 
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
    } 
    // seteth(true)  
    
  }

  const bscWeb3 = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
        
      });
      //window.ethereum.on('chainChanged', handler:  (chainId: 0x38))
      setbscaddress("BNB Connected");
      setethaddress("Connect Eth"); 
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
    } 
    // setbsc(true)  
     
  }


  const loadWeb3 = async () => {
    setShowMeta(true);
    setShow(false);
    setShowWallet(false);
    let isConnected = false;
    try {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        isConnected = true;
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        isConnected = true;
      } else {
        isConnected = false;
        setconnected(false);
        console.log(
          'Metamask is not installed, please install it on your browser to connect.'
        );
        alert(
          'Metamask is not installed, please install it on your browser to connect.'
        );
      }
      if (isConnected === true) {
        let accounts = await getAccounts();
        setAccount(accounts[0]);
        accountAd = accounts[0];
        setConnect('Connected');
        setWalletValue(accounts[0]);
        addresvalue = accountAd.substring(0, accountAd.length - 31) + '...';
        bscWeb3();
        setaddress(addresvalue);
        setbscaddress("BNB Connected");
        setethaddress("Connect Eth");
        setbscaddressconnected(true);
        loadMyEth();
        loadMyBnb();
        //getreferralAddress();
        console.log(referral);
        getbnbData()
        getethData()
        //getData();
        setshowreferral(true);
        sethideconnect(false)
        sethidenetwork(true)
        //loadNFTs();
        //loadMyNFTs()
        //loadListedNFTs();

        setconnected(true);
        // getMasterData();

        let accountDetails = null;
        window.ethereum.on('accountsChanged', function (accounts) {
          setAccount(accounts[0]);
          accountAd = accounts[0];
          setConnect('Connected');
          setWalletValue(accounts[0]);
          addresvalue = accountAd.substring(0, accountAd.length - 31) + '...';

          setaddress(addresvalue);
          // console.log(accounts);
        });
      }
    } catch (error) {
      console.log('Error while connecting metamask', error);
      // alert("Error while connecting metamask");
    }
  };

  const loadWeb3eth = async () => {
    setShowMeta(true);
    setShow(false);
    setShowWallet(false);
    let isConnected = false;
    try {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        isConnected = true;
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        isConnected = true;
      } else {
        isConnected = false;
        setconnected(false);
        console.log(
          'Metamask is not installed, please install it on your browser to connect.'
        );
        alert(
          'Metamask is not installed, please install it on your browser to connect.'
        );
      }
      if (isConnected === true) {
        let accounts = await getAccounts();
        setAccount(accounts[0]);
        accountAd = accounts[0];
        setConnect('Connected');
        setWalletValue(accounts[0]);
        addresvalue = accountAd.substring(0, accountAd.length - 31) + '...';
        ethWeb3();
        setaddress(addresvalue);
        setethaddress("Eth Connected");
        setbscaddress("Connect BNB");
        setethaddressconnected(true);
        loadMyEth();
        loadMyBnb();
        //getreferralAddress();
        console.log(referral);

        getbnbData()
        getethData()
        //getData();
        setshowreferral(true);
        sethideconnect(false)
        sethidenetwork(true)
        //loadNFTs();
        //loadMyNFTs()
        //loadListedNFTs();

        setconnected(true);
        // getMasterData();

        let accountDetails = null;
        window.ethereum.on('accountsChanged', function (accounts) {
          setAccount(accounts[0]);
          accountAd = accounts[0];
          setConnect('Connected');
          setWalletValue(accounts[0]);
          addresvalue = accountAd.substring(0, accountAd.length - 31) + '...';

          setaddress(addresvalue);
          // console.log(accounts);
        });
      }
    } catch (error) {
      console.log('Error while connecting metamask', error);
      // alert("Error while connecting metamask");
    }
  };

  const getAccounts = async () => {
    const web3 = window.web3;
    try {
      let accounts = await web3.eth.getAccounts();
      console.log(accounts);
      return accounts;
    } catch (error) {
      console.log('Error while fetching acounts: ', error);
      return null;
    }
  };

  // eslint-disable-next-line
  const isLockedAccount = async () => {
    try {
      let accounts = await getAccounts();
      if (accounts.length > 0) {
        console.log('Metamask is unlocked');
      } else {
        console.log('Metamask is locked');
      }
    } catch (error) {
      alert('Error while checking locked account');
    }
  };

  // const getData = async () => {
  //   try {
  //     const web3 = window.web3;
  //     let contract = new web3.eth.Contract(contractabi, contractAddress);
  //     let contract1 = new web3.eth.Contract(contractabi1, contractAddress1);
  //     // setstartPrice(accountDetails.sPrice);
  //     let Earned = await contract.methods.earned(accountAd).call();
  //     let Earnedineth = web3.utils.fromWei(Earned, 'ether');
  //     setuserearned(Earnedineth);
  //     let Staked = await contract.methods.getStaked(accountAd).call();
  //     let Stakedineth = web3.utils.fromWei(Staked, 'ether');
  //     setuserstake(Stakedineth);
  //     let totalStaked = await contract.methods.gettotalStaked().call();
  //     let totalStakedineth = web3.utils.fromWei(totalStaked, 'ether');
  //     settotalstaked(totalStakedineth);
  //     let Bal = await contract1.methods.balanceOf(accountAd).call();
  //     let Balineth = web3.utils.fromWei(Bal, 'ether');
  //     setuserbal(Balineth);
  //     let Amethyst = await contract.methods.getAmethyst().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setamethyst(Amethyst);
  //     let Aquamarine = await contract.methods.getAquamarine().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setaquamarine(Aquamarine);
  //     let Garnet = await contract.methods.getGarnet().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setgarnet(Garnet);
  //     let Blue = await contract.methods.getBlueSaphire().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setblue(Blue);
  //     let Ruby = await contract.methods.getRuby().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setruby(Ruby);
  //     let Emerald = await contract.methods.getEmerald().call();
  //     // let Balineth = web3.utils.fromWei(Bal,'ether');
  //     setemerald(Emerald);
  //     console.log('earned', Amethyst);
  //     console.log('userstake', userstake);
  //     console.log('totalstaked', totalstaked);
  //     console.log('userbal', userbal);

  //     // setTotalSupply(ttlSupply);
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  // const getreferralAddress = () => {
  //   try {
  //     let url = window.location.href;
  //     if (url.includes('?ref=')) {
  //       let getAddress = window.location.href.split('?ref=')[1];
  //       let final = getAddress.slice(0, 42);
  //       getDirectFromUrl = final;

  //       getDirectFromUrl = getDirectFromUrl
  //         ? getDirectFromUrl
  //         : refDefaultAddress;
  //       setreferral(getDirectFromUrl);
  //       console.log(referral);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const walletconnect = async () => {
    setShowWallet(true);
    let isConnected = false;
    try {
      // setErrorState(false);
      console.log('This is   setErrorState(false);');
      // const provider = new WalletConnectProvider({
      //     infuraId: "6d2b77cc1e1d45a7a12b25035aa39ce2",
      // });

      const provider = new WalletConnectProvider({
        chainId: 56,
        rpc: {
          56: 'https://bsc-dataseed.binance.org',
        },
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();

      if (provider) {
        window.web3 = new Web3(provider);
        isConnected = true;
      } else {
        isConnected = false;
        setconnected(false);
        // setErrorState(true);
        console.log('This is setErrorState(true)');
        // let options = {};
        // options = {
        //   place: "tr",
        //   message: "wallet connect is not connected",
        //   type: "primary",
        //   icon: "",
        //   autoDismiss: 7,
        // };
        // notificationAlertRef.current.notificationAlert(options);
        // // "Metamask is not installed, please install it on your browser to connect.",
      }
      if (isConnected === true) {
        setconnected(true);
        const web3 = window.web3;
        let accounts = await web3.eth.getAccounts();
        web3.eth.net.getId().then((netId) => {
          // console.log("(accounts[0], 2)", (accounts))
          setWalletValue(accounts[0]);
          setAccount(accounts[0]);
          setConnectWallet(accounts[0]);
          accountAd = accounts[0];
          addresvalue = accountAd.substring(0, accountAd.length - 31) + '...';
          setbscaddress("Disconnect BNB");
          setbnbdisconnectbutton(false)
          setaddress(addresvalue);
          sethideconnect(false)
          sethidewalletnetwork(true)
          getbnbData()
          loadMyBnb()
          getethData()
          loadMyEth()


          //getreferralAddress();
          console.log(referral);
         // getData();
          setshowreferral(true);
          // getData();
          //loadNFTs();
          //loadMyNFTs()
          // loadListedNFTs();

          switch (netId) {
            case 1:
              console.log('(accounts[0], 2)', (accounts[0], 2));
              console.log('This is mainnet');
              break;
            case 2:
              console.log('This is the deprecated Morden test network.');
              break;
            case 3:
              console.log('This is the ropsten test network.');
              break;
            default:
              console.log('(accounts[0], 2)', (accounts[0], 1));

            // console.log("This is an unknown network.");
          }
        });
        // this.props.dispatch(login(accounts[0]));

        window.ethereum.on('accountsChanged', function (accounts) {
          // this.props.dispatch(login(accounts[0]));
          web3.eth.net.getId().then((netId) => {
            switch (netId) {
              case 1:
                console.log('This is mainnet');
                break;
              case 2:
                console.log('This is the deprecated Morden test network.');
                break;
              case 3:
                console.log('This is the ropsten test network.');
                break;
              default:
                console.log('This is an unknown network.');
            }
          });
        });
      }
    } catch (error) {
      console.log('error', error);
      setconnected(false);
    }
  };

  const walletdisconnect = async () => {
   
    try {
      // setErrorState(false);
      console.log("This is   disconnect");
      // const provider = new WalletConnectProvider({
      //     infuraId: "6d2b77cc1e1d45a7a12b25035aa39ce2",
      // });

      const provider = new WalletConnectProvider({
        chainId: 56,
        rpc: {
          56: 'https://bsc-dataseed.binance.org',
        },
      });

      //  Enable session (triggers QR Code modal)
      await provider.disconnect();
      setbnbdisconnectbutton(true);
      setbscaddress("Connect BNB");
   }
   catch (error) {
    console.log("error", error);
    
  }}

  const walletethdisconnect = async () => {
   
    try {
      // setErrorState(false);
      console.log("This is   disconnect");
      // const provider = new WalletConnectProvider({
      //     infuraId: "6d2b77cc1e1d45a7a12b25035aa39ce2",
      // });

      const provider = new WalletConnectProvider({
        chainId: 1,
        rpc: {
          1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        },
      });

      //  Enable session (triggers QR Code modal)
      await provider.disconnect();
      setethdisconnectbutton(true);
      setethaddress("Connect Eth");
   }
   catch (error) {
    console.log("error", error);
    
  }}

  const walletethconnect = async () => {
    setShowWallet(true);
    let isConnected = false;
    try {
      // setErrorState(false);
      console.log("This is   setErrorState(false);");
      // const provider = new WalletConnectProvider({
      //     infuraId: "6d2b77cc1e1d45a7a12b25035aa39ce2",
      // });

      const provider = new WalletConnectProvider({
        chainId: 1,
        rpc: {
          1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        },
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();

      if (provider) {
        window.web3 = new Web3(provider);
        isConnected = true;
      } else {
        isConnected = false;
        setconnected(false);
        // setErrorState(true);
        console.log("This is setErrorState(true)");
        // let options = {};
        // options = {
        //   place: "tr",
        //   message: "wallet connect is not connected",
        //   type: "primary",
        //   icon: "",
        //   autoDismiss: 7,
        // };
        // notificationAlertRef.current.notificationAlert(options);
        // // "Metamask is not installed, please install it on your browser to connect.",
      }
      if (isConnected === true) {
        setconnected(true);
        const web3 = window.web3;
        let accounts = await web3.eth.getAccounts();
        web3.eth.net.getId().then((netId) => {
          // console.log("(accounts[0], 2)", (accounts))
          setWalletValue(accounts[0]); 
          setAccount(accounts[0]);
          setConnectWallet(accounts[0]);
          accountAd = accounts[0];
          addresvalue = accountAd.substring(0, accountAd.length - 31) + "...";
          setethaddress("Disconnect Eth");
          setethdisconnectbutton(false)
          setaddress(addresvalue);
          sethideconnect(false)
          sethidewalletnetwork(true)
          getethData()
          loadMyEth()
          getbnbData()
          loadMyBnb()
          //getData();
          //loadNFTs();
          //loadMyNFTs()
          //loadListedNFTs();

          switch (netId) {
            case 1:
              console.log("(accounts[0], 2)", (accounts[0], 2));
              console.log("This is mainnet");
              break;
            case 2:
              console.log("This is the deprecated Morden test network.");
              break;
            case 3:
              console.log("This is the ropsten test network.");
              break;
            default:
              console.log("(accounts[0], 2)", (accounts[0], 1));

            // console.log("This is an unknown network.");
          }
        });
        // this.props.dispatch(login(accounts[0]));

        window.ethereum.on("accountsChanged", function (accounts) {
          // this.props.dispatch(login(accounts[0]));
          web3.eth.net.getId().then((netId) => {
            switch (netId) {
              case 1:
                console.log("This is mainnet");
                break;
              case 2:
                console.log("This is the deprecated Morden test network.");
                break;
              case 3:
                console.log("This is the ropsten test network.");
                break;
              default:
                console.log("This is an unknown network.");
            }
          });
        });
      }
    } catch (error) {
      console.log("error", error);
      setconnected(false);
    }
  };

  var getethData = async () => {
    try {
      console.log("contract", "getData");
      const web3 = new Web3("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161")
      //console.log("contract", contract);
      let contract2 = new web3.eth.Contract(contractabi2, contractAddress2);
      console.log("contract", contract2);

      let ethbal =await new web3.eth.getBalance(accountAd);
      let ether = web3.utils.fromWei(ethbal, 'ether');
      console.log("ethbal", ethbal);
      setethbalance(ether)
      console.log("ethbal", ethbalance);

      let contractsupplyeth = await contract2.methods.gettotalstakedEth().call();
      console.log("mmm", contractsupplyeth)
      let ethsupply = web3.utils.fromWei(contractsupplyeth, 'ether');
      setethersupply(ethsupply)

      //let contractsupply = await contract2.methods.totalSupply().call();
      //console.log("mmm", contractsupply)
      //setsupply(contractsupply)

      // let contractethcost = await contract2.methods.cost().call();
      // console.log("mmm", contractethcost)
      // setethcost(contractethcost)

      


    } catch (e) {
      console.log("error", e);
    }
  };

  var getbnbData = async () => {
    try {
      console.log("contract", "getData");
      const web3 =new Web3("https://bsc-dataseed.binance.org");
      //console.log("contract", contract);
      let contract1 = new web3.eth.Contract(contractabi1, contractAddress1);
      console.log("contract", contract1);

      let bnbbal = await new web3.eth.getBalance(accountAd);
      let bnbs = web3.utils.fromWei(bnbbal, 'ether');
      console.log("bnbbal", bnbbal);
      setbnbbalance(bnbs);
      console.log("bnbbal", bnbbalance);

      let contractsupply = await contract1.methods.gettotalstakedbnb().call();
      console.log("mmm", contractsupply)
      let bnbsupply = web3.utils.fromWei(contractsupply, 'ether');
      setsupply(bnbsupply)


      // let contractbnbcost = await contract1.methods.cost().call();
      // //let contractbnbcost1 = web3.utils.fromWei(contractbnbcost, 'ether');
      // console.log("mmm", contractbnbcost)
      // setbnbcost(contractbnbcost)




    } catch (e) {
      console.log("error", e);
    }
  };

  const loadMyBnb = async () => {
    //loadMyNFTs();   
    const web3 = new Web3("https://bsc-dataseed.binance.org");
    let contract = new web3.eth.Contract(contractabi1, contractAddress1);
    console.log("kkkk", contract)
    //let contract1 = new web3.eth.Contract(contractabi2, contractAddress2);
    const data6 = await contract.methods.getStakedBnb(accountAd).call();
    //console.log("market", data)

    const items6 = await Promise.all(data6.map(async i => {
      //const tokenUri = await contract.methods.tokenURI(i.tokenId).call();
      //console.log("market", tokenUri)
      //const meta = await axios.get(tokenUri)
      //console.log("market", meta)
      //let priceee = web3.utils.fromWei(i.priceee);
      let price = i.price;
      let pricee = web3.utils.fromWei(price, 'ether');
      //let price = i.price;
      let item = {
        pricee,
        price,
        itemIdd: i.itemId,
        itemId: i.itemId,
        staker: i.staker,
        deadline: i.deadline,
        //image: meta.data.image,
       // name: meta.data.name,
        //description: meta.data.description,
        //category: meta.data.category,
        //network: meta.data.network,
      }
      return item
    }))
    setmybnb(items6)
    console.log("market", bnbmyOnfts)
    setLoadingState('loaded')
  }

  const loadMyEth = async () => {
    //loadMyNFTs();   
    const web3 =   new Web3("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161")
    //let contract = new web3.eth.Contract(contractabi1, contractAddress1);
    //console.log("kkkk", contract)
    let contract1 = new web3.eth.Contract(contractabi2, contractAddress2);
    console.log("kkkk", contract1)
    // const tokenUr = await contract1.methods.tokenURI(1).call();
    // console.log("market", tokenUr)

    const data7 = await contract1.methods.getStakedEth(accountAd).call();
    console.log("market", data7)

    const items7 = await Promise.all(data7.map(async i => {
      // const tokenUri = await contract1.methods.tokenURI(i.tokenId).call();
      // console.log("market", tokenUri)
      // const meta = await axios.get(tokenUri)
      // console.log("market", meta)
      //let priceee = web3.utils.fromWei(i.priceee);
      let price = i.price;
      let pricee = web3.utils.fromWei(price, 'ether');
      //let price = i.price;
      let item = {
        pricee,
        price,
        itemIdd: i.itemId,
        itemId: i.itemId,
        staker: i.staker,
        deadline: i.deadline,
        // image: meta.data.image,
        // name: meta.data.name,
        // description: meta.data.description,
        // category: meta.data.category,
        // network: meta.data.network,
      }
      return item
    }))
    setmyeth(items7)
    console.log("market", ethmyOnfts)
    setLoadingState('loaded')
  }

  const ref = useRef(null);
  const accountRef = useRef(null);

  useEffect(()=>{
  setethconnect(false)
  setbnbconnect(false)
  },[])

  return (
    <ActiveContext.Provider
      value={{
        active,
        setActive,
        address,
        walletconnect,
        loadWeb3,
        loadWeb3eth,
        bscaddress,
        ethaddress,
        bscaddressconnected,
        ethaddressconnected,
        bscWeb3,
        ethWeb3,
        connectWallet,
        show,
        showmeta,
        showwallet,
        setShow,
        account,
        walletvalue,
        ref,
        accountRef,
        accessToken,
        setAccessToken,
        ethmyOnfts,
        bnbmyOnfts,
        ethconnect,
        bnbconnect,
        hideconnect, hidenetwork,hidewalletnetwork, bscWeb3,ethWeb3,walletethconnect,loadMyEth,getethData,loadMyBnb,getbnbData,
        walletdisconnect,walletethdisconnect,bnbdisconnectbutton,ethdisconnectbutton
      }}
    >
      <div className='app'>
        <Toaster />
        <Router>
          <Layout>
            <Routes>
              <Route
                exact
                path='/'
                element={
                  <HomePage
                    // showreferral={showreferral}
                    // referral={referral}
                    // account={account}
                    // getreferralAddress={getreferralAddress}
                  />
                }
              />
              <Route exact path='/faq' element={<FaqPage />} />
              <Route exact path='/dashboard' element={<Dashboard supply= {supply} ethersupply= {ethersupply} bnbbalance= {bnbbalance} ethbalance= {ethbalance} mybnb={mybnb} myeth={myeth} bnbconnectfunc={bnbconnectfunc} ethconnectfunc={ethconnectfunc}
                   />} />
              <Route exact path='/stakebnb' element={<Stakebnb loadMyBnb={loadMyBnb} walletvalue= {walletvalue} supply= {supply} bnbbalance= {bnbbalance} bnbconnectfunc={bnbconnectfunc} ethconnectfunc={ethconnectfunc}/>} />
              <Route exact path='/stakeeth' element={<Stakeeth  walletvalue= {walletvalue} loadMyEth={loadMyEth} ethbalance= {ethbalance} bnbconnectfunc={bnbconnectfunc} ethconnectfunc={ethconnectfunc}/>} />
              
              <Route exact path='/stake' element={<Stake walletvalue= {walletvalue}/>} />     
            </Routes>
          </Layout>
        </Router>
      </div>
    </ActiveContext.Provider>
  );
};

export default App;
