import { useState } from 'react';
import Modal from 'react-modal';
import OTPInput from 'otp-input-react';
import arrow from '../assets/arrow.png';
import { toast } from 'react-hot-toast';

const OtpVerificationModal = ({ isOpen, setIsOpen, handleUpload }) => {
  const [otp, setotp] = useState('');

  const OTPCodeVerification = () => {
    window?.confirmationResult
      ?.confirm(otp)
      ?.then(async (res) => {
        handleUpload();
      })
      .classname((error) => {
        console.log('RIZWAN :: ', error);
      });
    toast.error('Otp value is incorrect');
  };
  const handleOtpVerification = () => {
    if (otp.length < 6) {
      toast.error('Otp value is incomplete');
      return;
    }
    OTPCodeVerification();
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        // onRequestClose={() => setIsOpen(false)}
        onAfterOpen={() => (document.body.style.overflow = 'hidden')}
        onAfterClose={() => (document.body.style.overflow = 'unset')}
        overlayClassName='otp-modal-overlay'
        className='otp-modal'
      >
        <OTPInput
          autoFocus
          OTPLength={6}
          otpType='number'
          value={otp}
          onChange={setotp}
          disabled={false}
        ></OTPInput>
        <div className='form__verify-otp' onClick={handleOtpVerification}>
          OTP Verify
          <img src={arrow} className='otp__arrow' />
        </div>
      </Modal>
    </>
  );
};

export default OtpVerificationModal;
