import Modal from 'react-modal';

import { useContext, useState } from 'react';
import arrow from '../assets/arrow.png';
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth } from '../firebase';
import { setCookiesforLogin } from '../constants/utils';
import { toast } from 'react-hot-toast';
import LoadingModal from './LoadingModal';
import { ActiveContext } from '../App';

const LoginModal = ({ className = 'right__text' }) => {
  const { setAccessToken } = useContext(ActiveContext);

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((data) => {
        const { _tokenResponse, user } = data;
        const { accessToken, uid, emailVerified } = user;
        if (emailVerified) {
          setAccessToken(uid);
          console.log("1",accessToken)
          console.log("2",uid)
          setCookiesforLogin({ accessToken, uid, ..._tokenResponse });
          toast.success('Successfully Loggedin');
          setIsLoading(false);
          setIsOpen(false);
        } else {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              toast.success('Please verify your email first');
              setIsLoading(false);
              toggle();
            })
            .catch((error) => {
              toast.error('Error sending email verification', error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        toast.error('Invalid Password');
        setIsLoading(false);
      });
  };

  function handleForgotPassword(email) {
    if (values.email) {
      setIsLoading(true);
      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          // Email sent.
          toast.success('Reset password email has been sent');
          toggle();
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error('Email not found');
          setIsLoading(false);
        });
      return;
    }
    toast.error('email is missing');
  }

  return (
    <>
      <button className={className} onClick={toggle} id='collabme1'>
        Login
      </button>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={toggle}
          onAfterOpen={() => (document.body.style.overflow = 'hidden')}
          onAfterClose={() => (document.body.style.overflow = 'unset')}
          overlayClassName='login-modal-overlay'
          className='login-modal'
        >
          <div className='form__group'>
            <input
              className='colabe-input'
              placeholder='Enter email '
              name='email'
              type='email'
              value={values['email']}
              onChange={handleChange}
            />
            <input
              type='password'
              className='colabe-input'
              placeholder='Enter password'
              value={values['password']}
              name='password'
              onChange={handleChange}
            />
          </div>
          <div className='forgot__password' onClick={handleForgotPassword}>
            Forgot password?
          </div>
          <div className='form__login' onClick={handleSubmit}>
            Login
            <img src={arrow} className='login__arrow' />
          </div>
        </Modal>
      )}
      {isLoading && <LoadingModal isLoading={isLoading} />}
    </>
  );
};

export default LoginModal;
