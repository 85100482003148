import React, { useState } from 'react';
import Pdf from '@mikecousins/react-pdf';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-hot-toast';
import arrow from '../assets/arrow.png';

const Signature = ({
  pdf,
  setFile,
  toggle,
  setSignatureCanvas,
  signatureCanvas,
  values,
  setValues,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((val) => {
      return { ...val, [name]: value };
    });
  };
  // const handleSave = async () => {
  //   console.log(signatureCanvas);
  //   debugger;
  //   const dataUrl = signatureCanvas.getTrimmedCanvas().toDataURL('image/png');
  //   const existingPdfBytes = await fetch(pdf).then((res) => res.arrayBuffer());

  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const pngImage = await pdfDoc.embedPng(dataUrl);
  //   const pages = pdfDoc.getPages();
  //   const firstPage = pages[5];
  //   const { width, height } = firstPage.getSize();
  //   const pngDims = pngImage.scale(0.5);

  //   firstPage.drawImage(pngImage, {
  //     x: width / 2 - pngDims.width / 2,
  //     y: height / 2 - pngDims.height / 2,
  //     width: pngDims.width,
  //     height: pngDims.height,
  //   });

  //   const pdfBytes = await pdfDoc.save();
  //   const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  //   // window.open(URL.createObjectURL(blob));
  //   setFile(blob);
  //   toggle();
  // };

  const handleSave = async () => {
    if (!signatureCanvas.isEmpty()) {
      const dataUrl = signatureCanvas.getTrimmedCanvas().toDataURL('image/png');
      const existingPdfBytes = await fetch(pdf).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pngImage = await pdfDoc.embedPng(dataUrl);
      const pages = pdfDoc.getPages();
      const firstPage = pages[5];
      const { width, height } = firstPage.getSize();
      const pngDims = pngImage.scale(0.5);

      firstPage.drawImage(pngImage, {
        x: width / 2 - pngDims.width / 2,
        y: height / 2 - pngDims.height / 2,
        width: pngDims.width,
        height: pngDims.height,
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      // window.open(URL.createObjectURL(blob));
      setFile(blob);
      toggle();
    } else {
      toast.error('Please sign before saving.');
    }
  };

  return (
    <div className='signature'>
      <Pdf file={pdf} page={pageNumber}>
        {({ pdfDocument, pdfPage, canvas }) => (
          <>
            {!pdfDocument && <span>Loading...</span>}
            {canvas}
            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <nav className='pdf--actions'>
                <ul className='pager'>
                  <li className='previous'>
                    <button
                      disabled={pageNumber === 1}
                      onClick={() => setPageNumber(pageNumber - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  <li className='next'>
                    <button
                      disabled={pageNumber === pdfDocument.numPages}
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}
      </Pdf>
      <div className='signature__section'>
        <div className='form__group'>
          <input
            type='text'
            className='colabe-input'
            placeholder='First Name'
            name='firstName'
            value={values['firstName']}
            onChange={handleChange}
          />
          <input
            type='text'
            className='colabe-input'
            placeholder='Last Name'
            name='lastName'
            value={values['lastName']}
            onChange={handleChange}
          />
        </div>
        <div class='signature__text'>SIGNATURE HERE</div>
        <SignatureCanvas ref={(ref) => setSignatureCanvas(ref)} />
        {/* <button onClick={handleSave}>Save</button> */}
        <div className='form__register' onClick={handleSave}>
          Save
          <img src={arrow} className='register__arrow' />
        </div>
      </div>
    </div>
  );
};

export default Signature;
