import { TailSpin } from 'react-loader-spinner';
import Modal from 'react-modal';

const LoadingModal = ({ isLoading }) => {
  return (
    <Modal
      isOpen={isLoading}
      //   onRequestClose={toggle}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
      overlayClassName='loading-modal-overlay'
      className='loading-modal'
    >
      <TailSpin
        height='120'
        width='120'
        color='#f6d558'
        ariaLabel='tail-spin-loading'
        radius='1'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
    </Modal>
  );
};

export default LoadingModal;
