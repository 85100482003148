import { useContext } from 'react';
import { useState } from 'react';

import { getDatabase, ref, set, child, push } from 'firebase/database';
import {
  ref as fileRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { storage, auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  sendEmailVerification,
  RecaptchaVerifier,
} from 'firebase/auth';

import { ActiveContext } from '../App';
import arrow from '../assets/arrow.png';
import down from '../assets/down.png';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import SignatureModal from './SignatureModal';
import OtpVerificationModal from './OtpVerificationModal';
import LoadingModal from './LoadingModal';
const crypto = require('crypto-browserify');

function RegistrationForm() {
  const { accountRef } = useContext(ActiveContext);
  const generateKey = (tableName) => {
    const db = getDatabase();
    return push(child(ref(db), tableName)).key;
  };
  const id = generateKey('Users');
  const db = getDatabase();
  // const usersReferrence = ref(db, 'Users/' + id);

  const [values, setValues] = useState({
    firstName: '',
    password: '',
    lastName: '',
    mobile: '',
    email: '',
    country: '',
    address: '',
  });
  const [file, setFile] = useState('');
  const [documentFile, setDocumentFile] = useState('');
  const [signatureCanvas, setSignatureCanvas] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [OtpModal, setOtpModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    checked && setIsOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((val) => {
      return { ...val, [name]: value };
    });
  };

  const isvalidateForm = () => {
    const newValues = { ...values, documentFile, file };
    for (const key in newValues) {
      if (newValues[key] === '' || !isChecked) {
        return false;
      }
    }
    return true;
  };
  const handleReset = () => {
    setValues({
      firstName: '',
      password: '',
      lastName: '',
      mobile: '',
      email: '',
      country: '',
      address: '',
    });
    setFile('');
    setDocumentFile('');
    setIsChecked(false);
  };
  const handleResetSameFile = () => {
    const files = window.document.getElementById('applicationfileUpload');
    files['value'] = ''; // clear the selected file
    setDocumentFile(null); // clear the documentFile state
  };
  const handleImageUpload = () => {
    handleResetSameFile();
    const files = window.document.getElementById('applicationfileUpload');
    files.click();
    files.onchange = () => {
      const selectedFile = files.files[0];
      setDocumentFile(selectedFile);
    };
  };

  const hashPassword = (password) => {
    const hash = crypto.createHash('sha256');
    hash.update(password);
    return hash.digest('hex');
  };
  const hashedPassword = hashPassword(values.password);

  const sendVerificationEmail = (email) => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        toast.success('Email verification sent');
      })
      .catch((error) => {
        console.log('Error sending email verification', error);
      });
  };
  const handleRegistration = () => {
    if (!isvalidateForm()) {
      toast.error('All fields are mandatory');
    }
    setIsDisable(true);
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((res) => {
        const { uid } = res.user;
        const storageRef1 = fileRef(storage, `/files/terms&conditions`);
        const storageRef2 = fileRef(storage, `/files/${documentFile.name}`);

        const uploadTask1 = uploadBytesResumable(storageRef1, file);
        const uploadTask2 = uploadBytesResumable(storageRef2, documentFile);

        Promise.all([uploadTask1, uploadTask2])
          .then((results) => {
            const urls = results.map((result) => getDownloadURL(result.ref));
            Promise.all(urls)
              .then(([url1, url2]) => {
                const usersReferrence = ref(db, 'Users/' + uid);

                set(usersReferrence, {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: hashedPassword,
                  mobileNumber: values.mobile,
                  country: values.country,
                  address: values.address,
                  termsUrl: url1,
                  documentUrl: url2,
                })
                  .then(() => {
                    sendVerificationEmail(values.email);
                    setOtpModal(false);
                    handleReset();
                    setIsDisable(false);
                  })
                  .catch((e) => {
                    alert(e);
                    setIsDisable(false);
                  });
              })
              .catch((e) => {
                alert(e);
                setIsDisable(false);
              });
          })
          .catch((err) => {
            console.log(err);
            setIsDisable(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 900)
      setIsMobile(true);
  }, [typeof window]);

  return (
    <div className='registration-form' ref={accountRef}>
      <div id='recaptcha-container'></div>
      <div className='website__heading-title'>Registration</div>
      <div className='form__group'>
        <input
          type='text'
          className='colabe-input'
          placeholder='First Name'
          name='firstName'
          value={values['firstName']}
          onChange={handleChange}
        />
        <input
          type='text'
          className='colabe-input'
          placeholder='Last Name'
          name='lastName'
          value={values['lastName']}
          onChange={handleChange}
        />
      </div>
      <div className='form__group'>
        <input
          className='colabe-input'
          placeholder='Email Address'
          name='email'
          type='email'
          value={values['email']}
          onChange={handleChange}
        />
        <input
          className='colabe-input'
          placeholder='Password'
          value={values['password']}
          name='password'
          onChange={handleChange}
          type='password'
        />
      </div>
      <div className='form__group'>
        <input
          className='colabe-input'
          placeholder='Mobile Number'
          value={values['mobile']}
          name='mobile'
          type='number'
          onChange={handleChange}
        />
        <input
          type='text'
          className='colabe-input'
          placeholder='Country'
          value={values['country']}
          name='country'
          onChange={handleChange}
        />
      </div>
      <div className='form__group'>
        <input
          type='text'
          className='colabe-input'
          placeholder='Address / City'
          name='address'
          value={values['address']}
          onChange={handleChange}
        />
        <label
          className={
            isMobile && documentFile
              ? 'group__file-field mt--40'
              : 'group__file-field '
          }
          for='file-input'
          onClick={handleImageUpload}
        >
          <div className='custom-file-upload'>Choose File</div>
          <img src={down} />
          {documentFile && (
            <div
              className='group__file-preview'
              onClick={(event) => {
                event.stopPropagation();
                setDocumentFile('');
              }}
            >
              <div className='preview__name'>{documentFile.name}</div>
              <div className='preview--cancel'>x</div>
            </div>
          )}
        </label>
        <input
          type='file'
          id='applicationfileUpload'
          accept='.pdf,.doc,.docx'
          name='file'
        />
      </div>
      <div className='form__group'>
        <div className='form__checkbox'>
          <span className='checkbox__text'>Terms & Conditions</span>
          <input
            type='checkbox'
            name='RGPD'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className='checkmark'></span>
        </div>
        <div
          className={
            isDisable ? 'pointer-event-none form__register' : 'form__register'
          }
          onClick={handleRegistration}
        >
          REGISTER
          <img src={arrow} className='register__arrow' />
        </div>
      </div>
      {isOpen && (
        <SignatureModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          values={values}
          setValues={setValues}
          setFile={setFile}
          setIsChecked={setIsChecked}
          setSignatureCanvas={setSignatureCanvas}
          signatureCanvas={signatureCanvas}
        />
      )}
      {OtpModal && (
        <OtpVerificationModal
          isOpen={OtpModal}
          setIsOpen={setOtpModal}
          handleUpload={handleRegistration}
        />
      )}
      {isDisable && <LoadingModal isLoading={isDisable} />}
    </div>
  );
}

export default RegistrationForm;
