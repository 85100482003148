import cookie from 'js-cookie';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_SET_TIME,
  USER_ID,
  EMAIL,
  REGISTERED,
} from './constants';

export const setCookiesforLogin = (data) => {
  const date = Date.now();
  const access_token = data[ACCESS_TOKEN];
  const refresh_token = data[REFRESH_TOKEN];
  cookie.set(ACCESS_TOKEN, access_token, { expires: 1 });
  cookie.set(REFRESH_TOKEN, refresh_token, { expires: 1 });
  cookie.set(TOKEN_SET_TIME, date, { expires: 1 });
  cookie.set(EMAIL, data[EMAIL], { expires: 1 });
  cookie.set(USER_ID, data['uid'], { expires: 1 });
  cookie.set(REGISTERED, data[REGISTERED], { expires: 1 });
};

export const clearAllCookies = () => {
  Object.keys(cookie.get()).forEach(function (cookieName) {
    cookie.remove(cookieName);
  });
};

export const getCookie = (key) => {
  return cookie.get(key);
};
