import React from 'react';
import { useContext } from 'react';
import { ActiveContext } from '../../App';
import Accordian from './Accordian';

const FaqPage = () => {
  const { ref } = useContext(ActiveContext);

  return (
    <div className='page-faqs' ref={ref}>
      <div className='website__heading-title'>Frequently Asked Questions</div>

      {/* Getting Started  */}
      <div class='faqs-sec' id='Section6'>
        <div class='faqs-main'>
          <div id='accordionExample'>
            <div className='accordian__row'>
              <Accordian
                key='0'
                title='What is DeFi Staking?'
                content='DeFi (Decentralized Finance) is a way of providing financial services to users through smart contracts. Existing DeFi projects aim to provide higher annualized earnings for specific currencies.'
              />
              <Accordian
                key='1'
                title='About Crypto Bank DeFi Staking?'
                content={`There's a relatively high threshold for users of DeFi products. Crypto Bank DeFi Staking acts on behalf of users to participate in certain DeFi products, obtains and distributes realized earnings, and helps users to participate in DeFi products with a single click.`}
              />
            </div>
            <div className='accordian__row'>
              <Accordian
                key='2'
                title='After | participate in DeFi Staking, how is the earnings cycle calculated?'
                content='Once funds are successfully allocated to DeFi Staking, earnings are calculated beginning at 00:00 (UTC) the following day. The minimum earnings calculation period is one day; earnings for a period of less than one day will not be included in the earnings distribution.'
              />
              <Accordian
                key='3'
                title='How long do | need to lock-up my funds to participate in Defi Staking?'
                content='Currently, the lock-up period for regular products is 1 day. Funds will be unlocked and returned to your account on T + 1. However, as we add support for more types of products, the lock-up periods will vary.Lock-up periods for different products will be specified in the information on the product interface.'
              />
            </div>
            <div className='accordian__row'>
              <Accordian
                key='4'
                title='If | redeem early, can | earn part of the interest?'
                content='Early redemption will unfortunately deduct all interest-based income. This is because assets pledged to participate are locked in the smart-contract on-chain, and the redemption operation requires a more complicated manual operation to initiate and includes certain blockchain transfer fees.Please choose the appropriate lock-up time when subscribing to DeFi Staking products.'
              />
              <Accordian
                key='5'
                title='Why does the value of my earnings go up and down?'
                content='Your earnings may fluctuate from day to day depending on the prevailing market conditions, and the product you have selected'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
