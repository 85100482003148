import React, { useContext } from 'react';
import { ActiveContext } from '../App';
import arrow from '../assets/arrow.png';
import Binance from '../assets/binance.png';
import Ethereum from '../assets/ethereum.png';
import ConnectModal from '../ConnectModal';

// const CHARACTER_LIST = [{
//   nft: "card1",
//   name: "Doge Coin"
// },
// {
//   nft: "card2",
//   name: "Elon Cat"
// },
// {
//   nft: "card3",
//   name: "Baby Doge"
// },
// {
//   nft: "card4",
//   name: "Dog Elon Mars"
// },
// {
//   nft: "card5",
//   name: "Shib Elon"
// }
//  ]

const CHARACTER_LIST =[]

const Dashboard = ({mybnb, supply,myeth, ethersupply}) => {
  const { accountRef } = useContext(ActiveContext);
 
  return (
    <div className='dashboard' ref={accountRef}>
      <div className='website__heading-title'>Dashboard</div>
      <div className='dashboard__eth'>
        <div className='eth__content'><img src={Ethereum} style={{ "height":  "40px" }}/> Total eth Staked: {ethersupply}</div>
        <div className='eth__content'><img src={Binance} style={{ "height":  "40px" }}/> Total bnb Staked: {supply}</div>
      </div>
      {mybnb?.map((val) => (
      <div className='dashboard__bnb'>
        <div className='bnb__left'>
          <div className='left__content'><img src={Binance} style={{ "height":  "40px" }}/> Staked BNB Balance: {val?.pricee}</div>
          <div className='left__content'></div>
          <div className={'left--withdraw'}>
            
            <img src={arrow} className='withdraw__arrow' />Withdraw
          </div>
        </div>
        <div className='bnb__right-content'></div>
      </div>))}
      {myeth?.map((val) => (
      <div className='dashboard__bnb'>
        <div className='bnb__left'>
          <div className='left__content'><img src={Ethereum} style={{ "height":  "40px" }}/> Staked BNB Balance: {val?.pricee}</div>
          <div className='left__content'></div>
          <div className={'left--withdraw'}>
            
            <img src={arrow} className='withdraw__arrow' />Withdraw
          </div>
        </div>
        <div className='bnb__right-content'></div>
      </div>))}
    </div>
  );
};

export default Dashboard;
