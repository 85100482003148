import React, { useContext } from 'react';
import { ActiveContext } from '../App';
import arrow from '../assets/arrow.png';
import Binance from '../assets/binance.png';
import Ethereum from '../assets/ethereum.png';
import ConnectModal from '../ConnectModal';
import logo from '../assets/logoo.png';
import Web3 from 'web3';
import { Link, useLocation } from 'react-router-dom'
import {
  contractabi2,
  contractAddress2,
  refDefaultAddress,
  contractabi1,
  contractAddress1,
  ACCESS_TOKEN,
  // tokenAddress, tokenabi
} from '../constants/constants';


// const CHARACTER_LIST = [{
//   nft: "card1",
//   name: "Doge Coin"
// },
// {
//   nft: "card2",
//   name: "Elon Cat"
// },
// {
//   nft: "card3",
//   name: "Baby Doge"
// },
// {
//   nft: "card4",
//   name: "Dog Elon Mars"
// },
// {
//   nft: "card5",
//   name: "Shib Elon"
// }
//  ]

const CHARACTER_LIST =[]

const Stake = ({ethmyOnfts, bnbmyOnfts}) => {
  const { accountRef } = useContext(ActiveContext);
 
  return (
    <>
   
    
    <div className='dashboard' ref={accountRef}>
      

      {/* <div className='website__heading-title'>Dashboard</div>
      <div className='dashboard__eth'>
        <div className='eth__content'><img src={Ethereum} style={{ "height":  "40px" }}/> Total eth Staked:100</div>
        <div className='eth__content'><img src={Binance} style={{ "height":  "40px" }}/> Total bnb Staked:10</div>
      </div> */}
      <div className='website__heading-title'>STAKE AND GET REWARDED</div>
      <div className='dashboard__bnb'>
        <div className='bnb__left'>
          <div className='left__content'><img src={Binance} style={{ "height":  "40px" }}/> Stake BNB</div>
          <div className='left__content'>Duration 365days</div>
          <div className={'left--withdraw'} style={{ "text-decoration":  "none" }}>
             <Link to = "/stakebnb" style={{ "text-decoration":  "none" }}>Stake Now</Link>
            <img src={arrow} className='withdraw__arrow' />
          </div>
        </div>
        <div className='bnb__right-content'>Annualized Interest: 9%</div>
      </div>
      <div className='dashboard__bnb'>
        <div className='bnb__left'>
          <div className='left__content'><img src={Ethereum} style={{ "height":  "40px" }}/> Stake ETH </div>
          <div className='left__content'>Duration 365days</div>
          <div className={'left--withdraw'} style={{ "text-decoration":  "none" }}>
          <Link to = "/stakeeth" style={{ "text-decoration":  "none" }}>Stake Now</Link>
            <img src={arrow} className='withdraw__arrow' />
          </div>
        </div>
        <div className='bnb__right-content'>Annualized Interest: 8.35%</div>
      </div>
    </div>
    </>
  );
};

export default Stake;
