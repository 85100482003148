import React, { Fragment, useEffect, useState } from "react";
// import Carousel from "react-material-ui-carousel";
import "./stakebnb.css";
import Profile from '../../assets/binance.png';
import Web3 from 'web3';
import { Link, useLocation } from 'react-router-dom'
import {
  contractabi2,
  contractAddress2,
  refDefaultAddress,
  contractabi1,
  contractAddress1,
  ACCESS_TOKEN,
  // tokenAddress, tokenabi
} from '../../constants/constants';


const Stakebnb = ({ walletvalue, bnbconnectfunc,bnbbalance, loadMyBnb}) => {


  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [bnbvalue, setbnbValue] = useState();
  //const [setbnb, setbnbconnect] = useState();

  const sellinputbnb = async (e) => {
    try {
      const web3 = window.web3;
      // console.log("bscAddress true", typeof e.target.value);
      // console.log("bscAddress true", e.target.value === "");
      // console.log("bscAddress", window.web3.utils.toWei(e.target.value))
      setbnbValue(web3.utils.toWei(e.target.value));
      console.log("mmm", bnbvalue);
      console.log("nn", walletvalue)
    } catch (e) {
      console.log("error", e);
    }
  };

  const bnbstake = async () => {
    try {
      const web3 = window.web3;
      let contract = new web3.eth.Contract(contractabi1, contractAddress1);
      let accountDetails = await contract.methods
        .stake(bnbvalue)
        .send({
          from: walletvalue,
          gasLimit: 3000000,
          value:bnbvalue

        })
        .on("transactionHash", async (hash) => {
          console.log("Your transaction is pending");
        })
        .on("receipt", async (receipt) => {
          console.log("Your transaction is confirmed", receipt);
          loadMyBnb()
          //toast.success("Your transaction is confirmed");
          // loadWeb3()
        })
        .on("error", async (error) => {
          console.log("User denied transaction", error);
        });
    } catch (e) {
      console.log("error", e);
    }
  };
  

 

 



 
  return (
    <Fragment>
      
        <Fragment>
          
          
          <div className="ProductDetails">
            <div><img src = {Profile} style={{ "height":  "100px" }}/>
            <div className='website__heading-title'>STAKE BNB</div>
              
            </div>

            <div>
              <div className="detailsBlock-1">
                <h2>Address:{walletvalue}</h2>
                
              </div>
              
              <div className="detailsBlock-3">
                <h1>Wallet balance: {bnbbalance}BNB</h1>
                <div className="detailsBlock-3-1">
                  <div className="detailsBlock-3-1-1">
                   
                  </div>
                </div>

               
              </div>

              <div className="detailsBlock-4">
                Stake Amount : <input type= "number" onChange={sellinputbnb} style={{ "height":  "30px" }}/><p></p>
              </div>

              <button onClick={bnbstake} className="submitReview">
                Stake
              </button>
            </div>
          </div>

        
              
         
        </Fragment>
    
    </Fragment>
  );
};

export default Stakebnb;