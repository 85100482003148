import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

import { getStorage } from 'firebase/storage';

import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC28NqAbH1wSYEcUvVeBsZh2tg6Sw1lPKc',
  authDomain: 'crypto-bank-8e70d.firebaseapp.com',
  projectId: 'crypto-bank-8e70d',
  storageBucket: 'crypto-bank-8e70d.appspot.com',
  messagingSenderId: '695938211102',
  appId: '1:695938211102:web:53507efc1eb612a635bd0f',
  measurementId: 'G-5Y4RNZYY47',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { db, auth, storage };
