import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logoo.png';
import ConnectModal from '../../ConnectModal';
import ConnectModalEth from '../../ConnectModalEth';
import { ActiveContext } from '../../App';
import LoginModal from '../LoginModal';

import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { toast } from 'react-hot-toast';
import { clearAllCookies, getCookie } from '../../constants/utils';
import { ACCESS_TOKEN } from '../../constants/constants';

const DesktopHeader = ({ handleClick, handleClickAccount }) => {
  const { accessToken, setAccessToken,bnbconnect, ethconnect,hidenetwork, hideconnect,bscWeb3,ethWeb3,hidewalletnetwork,walletethconnect,walletconnect } = useContext(ActiveContext);
  const { access, setAccess } = useState(true);

  const { pathname } = useLocation();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // do something after the user is logged out
        clearAllCookies();
        setAccessToken('');
        toast.success('Successfully Logout');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNetworkChange = (e) => {
  
    let { value } = e.target;
    //setPetsValue(value);

    if (value === 'Binance') {
      bscWeb3()
     
    }
    else if(value === 'Ethereum') {
      ethWeb3()
     
    }
   


}

const handlewalletNetworkChange = (e) => {
  
  let { value } = e.target;
  //setPetsValue(value);

  if (value === 'Binance') {
    walletconnect()
   
  }
  else if(value === 'Ethereum') {
    walletethconnect()
   
  }
 


}

  return (
    <>
      <div className='header'>
        <div className='header__left'>
          <Link to='/'>
            <img className='left__logo' src={logo} />
          </Link>
        </div>
       
        <div className='header__right'>
        {!accessToken && (
          <div onClick={handleClickAccount} className='right__text'>
            Registration
          </div>
          )}
          {!accessToken && <LoginModal />}
          {!accessToken && (
          <div onClick={handleClick} className='right__text'>
            FAQ
          </div>
          )}
          {accessToken && (
            <div onClick={handleLogout} className='right__text'>
              Logout
            </div>
          )}
             {accessToken && (
              
               <Link to='/dashboard' style={{ "text-decoration":  "none" }} className='right__text'><div  className='right__text' >Dashboard</div></Link>
               
             )}
             {accessToken && ( 
            <Link to='/stake' style={{ "text-decoration":  "none" }} className='right__text'>
            <div  className='right__text' >
              stake
            </div>
            
            </Link>
             )}
              
            
              {accessToken && ( 
              <ConnectModal/>
              )}
               {accessToken && ( 
              <ConnectModalEth/>
              )}
            
            {/* {accessToken && (<div>
          {/* {bnbconnect? <div ><ConnectModal /> </div>:""}
          {ethconnect? <ConnectModal />:""} */}
           {/*{hidenetwork? <div >
                        <label>Network</label>
                        
                        <select name="network" id="network"
                          onChange={handleNetworkChange} >
                            <option value="Binance">Binance</option>
                            <option value="Ethereum">Ethereum</option>
                            {/*<option value="Polygon">Polygon</option>
                        </select>
                    </div>:null}
                    {hidewalletnetwork? <div className='right__text'>
                        <label>Network</label>
                        <select name="network" id="network"
                          onChange={handlewalletNetworkChange} >
                            <option value="Binance">Binance</option>
                            <option value="Ethereum">Ethereum</option>
                            {/*<option value="Polygon">Polygon</option>
                        </select>
                    </div>:null}
         {hideconnect? <div >
            <ConnectModal />
          </div>:null}
          </div>)} */}

        </div>
      </div>
    </>
  );
};

export default DesktopHeader;
