import React from 'react';

import bitcoin from '../assets/bitcoin.png';
import ethereum from '../assets/ethereum.png';
import metamask from '../assets/metamask.png';
import binance from '../assets/binance.png';
import tether from '../assets/tether.png';
import cardano from '../assets/cardano.png';
import solana from '../assets/solana.png';
import litecoin from '../assets/litecoin.png';
import xrpcoin from '../assets/xrpcoin.png';

import { useState } from 'react';

const Footer = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div className='footer'>
      {/* <div className='checkbox'>
        <div className='checkbox__container'>
          <span className='checkbox__text'>Terms & Conditions</span>
          <input
            type='checkbox'
            name='terms'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className='checkmark'></span>
        </div>
      </div> */}
      <div className='footer__links'>
        <a a='#'>
          <img src={xrpcoin} />
        </a>
        <a a='#'>
          <img src={binance} />
        </a>
        <a a='#'>
          <img src={ethereum} />
        </a>
        <a a='#'>
          <img src={bitcoin} />
        </a>
        {/* <a a='#'>
          <img src={tether} />
        </a> */}
        <a a='#'>
          <img src={cardano} />
        </a>
        <a a='#'>
          <img src={solana} />
        </a>
        <a a='#'>
          <img src={litecoin} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
