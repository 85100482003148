import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import { ActiveContext } from '../App';
import Footer from './Footer';
import Header from './Header/Header';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
