import React, { useContext, useEffect, useState } from 'react';
import {
  ACCESS_TOKEN,
  contractabi2,
  contractAddress2,
  refDefaultAddress,
} from '../../constants/constants';
import Web3 from 'web3';
import trans from '../../assets/trans.png';
import trust from '../../assets/trust.png';
import plat from '../../assets/plat.png';

import Table from '../../Components/PageTable';
import RegistrationForm from '../../Components/RegistrationForm';
import Dashboard from '../../Components/Dashboard';

import FaqPage from '../FaqPage';
import Signature from '../../Components/Signature';
import conditions from '../../assets/conditions.pdf';
import { useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import LoadingModal from '../../Components/LoadingModal';
import { getCookie } from '../../constants/utils';
import { ActiveContext } from '../../App';

const HomePage = ({ referral, account }) => {
  //const [referral, setreferral] = useState("0x2313C8D0D6757E1bd44bDabe7225be31EC20D85D");
  const { accessToken } = useContext(ActiveContext);

  const [bnbValue, setbnbValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  //let getDirectFromUrl;

  // const inputaddress = async (e) => {
  //   try {
  //     const web3 = window.web3;
  //     // console.log("bscAddress true", typeof e.target.value);
  //     // console.log("bscAddress true", e.target.value === "");
  //     // console.log("bscAddress", window.web3.utils.toWei(e.target.value))
  //     //setaddressValue(e.target.value);
  //     //console.log("mmm", addressvalue);
  //     //console.log("nn",walletvalue)
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  // const buyinputbnb = async (e) => {
  //   try {
  //     // console.log("bscAddress true", typeof e.target.value);
  //     // console.log("bscAddress true", e.target.value === "");

  //     if (e.target.value === '') {
  //       // console.log("bscAddress true10", typeof e.target.value);
  //       // console.log("bscAddress true0", e.target.value);
  //       setbnbValue(10000000000000000);
  //     } else {
  //       // console.log("bscAddress", window.web3.utils.toWei(e.target.value))
  //       setbnbValue(window.web3.utils.toWei(0.0000027));
  //     }
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };
  // const copyReferralLink = () => {
  //   try {
  //     let get = document.getElementById('refer').select();
  //     document.execCommand('copy');
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const buy = async () => {
  //   try {
  //     // console.log("accountDetails", referral);
  //     const web3 = window.web3;
  //     let contract = new web3.eth.Contract(contractabi, contractAddress);
  //     //setbnbValue(window.web3.utils.toWei("0.0000027"));

  //     // console.log("input", window.web3.utils.fromWei(enteredAmount));
  //     // console.log("input", window.web3.utils.toWei(enteredAmount));

  //     let accountDetails = await contract.methods
  //       .stake(referral)
  //       .send({
  //         from: account,
  //         gasLimit: 1000000,
  //         value: 28000000000,
  //       })
  //       .on('transactionHash', async (hash) => {
  //         console.log('Your transaction is pending');
  //       })
  //       .on('receipt', async (receipt) => {
  //         console.log('Your transaction is confirmed', receipt);
  //         //toast.success("Your transaction is confirmed");
  //       })
  //       .on('error', async (error) => {
  //         console.log('User denied transaction', error);
  //       });
  //   } catch (e) {
  //     console.log('error', e);
  //     console.log('error', e.mesage);
  //   }
  // };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleTransfer = () => {
    setIsTransferOpen(!isTransferOpen);
  };

  const location = useLocation();

  const handleVerifyEmail = (code) => {
    auth
      .applyActionCode(code)
      .then(() => {
        // Email verification successful, redirect the user to your site
        window.location.href =
          'https://prismatic-cheesecake-48559d.netlify.app/';
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const oobCode = searchParams.get('oobCode');
    if (oobCode) {
      handleVerifyEmail(oobCode);
    }
    console.log(oobCode);
    // send the oobCode to your server for verification
  }, [location.search]);

  return (
    <div className='home-page'>
      <div className='page__intro'>
        <div className='intro__content'>
          <div className='content__heading1'>Open an online</div>
          <div className='content__heading2'>
            Crypto Banking
            <br />
            Profile
          </div>

          <p className='content__sub-heading'>A NEW GENERATION OF BANKING</p>
        </div>
      </div>
      <div className='page__describe'>
        <div className='website__heading-title'>Why Crypto Bank?</div>
        <div className='describe__subheading'>
          Hybrid Decentralized network powered by cryptocurrencies and secured
          by Blockchain
        </div>
        <div className='describe__types'>
          <img className='types__img' src={trans} />
          <div className='types__content'>
            <div className='content__name'>Transparency</div>
            <div className='content__para'>
              At Crypto Bank we conduct all our operations on a Blockchain
              Secured Platform. This ensures transparency and security for our
              customer assets
            </div>
          </div>
        </div>
        <div className='describe__types'>
          <img className='types__img' src={plat} />
          <div className='types__content'>
            <div className='content__name'>Hybrid Decentralized Platform</div>
            <div className='content__para'>
              The new DeFi world is the most powerful emerging trend in
              financial services as it reduces cost, minimizes transaction time
              and allows customers to conduct transactions without interference
              from central and traditional banks. All according to the
              convenience and choice of the customer
            </div>
          </div>
        </div>
        <div className='describe__types'>
          <img className='types__img' src={trust} />
          <div className='types__content'>
            <div className='content__name'>Trust</div>
            <div className='content__para'>
              Customers need to trust the custodians of their wealth. Crypto
              Bank goes the extra mile and takes an active role in generating
              wealth for our customers, but we do this while giving customers
              control of their wealth generation process. Our customers are
              always comfortable, knowing their assets are being protected every
              minute of every hour of every day
            </div>
          </div>
        </div>
      </div>
      <Table />
      {accessToken ? <RegistrationForm /> : <RegistrationForm />}

      <FaqPage />
      <LoadingModal />
    </div>
  );
};

export default HomePage;
