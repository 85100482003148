import React, { useState, useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logoo.png';
import menu from '../../assets/menu.svg';
import AppButton from '../../common/AppButton';
import ConnectModal from '../../ConnectModal';
import ConnectModalEth from '../../ConnectModalEth';
import { Dropdown } from 'react-bootstrap';
import { ActiveContext } from '../../App';
import LoginModal from '../LoginModal';

import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { toast } from 'react-hot-toast';
import { clearAllCookies, getCookie } from '../../constants/utils';
import { ACCESS_TOKEN } from '../../constants/constants';

const MobileHeader = ({ handleClick, handleClickAccount }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const {
    address,
    loadNFTs,
    walletconnect,
    loadWeb3,
    connectWallet,
    show,
    showmeta,
    showwallet,
    setShow,
    account,
    nfts,
    walletvalue,
    accessToken,
    setAccessToken,hidenetwork, hideconnect,bscWeb3,ethWeb3,hidewalletnetwork,walletethconnect,
  } = useContext(ActiveContext);
  const { pathname } = useLocation();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // do something after the user is logged out
        clearAllCookies();
        setAccessToken('');
        toast.success('Successfully Logout');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className='mobile-header'>
        <Link to='/' onClick={() => setIsOpenMenu(false)}>
          <img src={logo} className='header-logo' />
        </Link>
        <img
          src={menu}
          className='header-hamberg'
          onClick={() => setIsOpenMenu(true)}
        />
      </div>
      <Menu
        right
        width={'100%'}
        id='mbl-menu'
        className='mbl-menu'
        overlayClassName='mbl-menu-overlay'
        isOpen={isOpenMenu}
        onClose={() => {
          setIsOpenMenu(false);
        }}
        onStateChange={(state) => setIsOpenMenu(state.isOpen)}
      >
        <div className='menu-items'>
          <div className='mobile__top'>
            <Link to='/' onClick={() => setIsOpenMenu(false)}>
              <img src={logo} className='header-logo' />
            </Link>
            <img
              src={menu}
              alt='menu-logo'
              className='header-hamberg'
              onClick={() => setIsOpenMenu(false)}
            />
          </div>
          <div className='menu-buttons'>
          {!accessToken && (
            <div
              onClick={() => {
                setIsOpenMenu(false);
                handleClickAccount();
              }}
              className={`page-links `}
            >
              Registration
            </div>
             )}

            {accessToken && (
              <div
                onClick={() => {
                  setIsOpenMenu(false);
                  handleLogout();
                }}
                className={`page-links `}
              >
                Logout
              </div>
            )}
            {!accessToken && (
              <Link to='#' onClick={() => setIsOpenMenu(false)}>
                <LoginModal className='page-links' />
              </Link>
            )}
            {accessToken && (
              <Link to='/dashboard' onClick={() => setIsOpenMenu(false)} className='page-links'>
                 Dashboard
              </Link>
            )}
            {accessToken && (
              <Link to='/stake' onClick={() => setIsOpenMenu(false)} className='page-links'>
                Stake
              </Link>
            )}
             {!accessToken && (
            <div
              onClick={() => {
                setIsOpenMenu(false);
                handleClick();
              }}
              className={`page-links `}
            >
              FAQ
            </div>
            )}
            {/* {accessToken && (
              <div className='mt--50'>
                <div className={`page-links `}>
                  <ConnectModal setIsOpenMenu={setIsOpenMenu} />
                </div>
              </div>
            )} */}
           
          {accessToken && (
            <div className='mt--50'>
            <div className={`page-links `}>
              <ConnectModal setIsOpenMenu={setIsOpenMenu}/>
              </div>
              </div>)}
             {accessToken && (
              <div className='mt--50'>
              <div className={`page-links `}>
              <ConnectModalEth setIsOpenMenu={setIsOpenMenu}/>
              </div>
              </div>)}
          </div>
        </div>
      </Menu>
    </>
  );
};

export default MobileHeader;
