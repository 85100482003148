const Table = () => {
  const headers = [
    'Digital Assets',
    'Duration',
    'Standarized Annualized Interest Rate',
    'Min Locked Staking Limit',
    'Max Locked Staking Limit',
  ];

  return (
    <div className='page_details'>
      <h1 className='website__heading-title mb--10'>DeFI Staking</h1>
      <div className='details__table'>
        <div className='table__heading'>
          {headers.map((header) => (
            <h3 className='heading__text'>{header}</h3>
          ))}
        </div>
        <hr className='heading__border' />
        <div className='table__description'>
          <p className='description__text'>BTC</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>8.50%</p>
          <p className='description__text'>3.5 BTC</p>
          <p className='description__text'>15 BTC</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>ETH</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>8.35%</p>
          <p className='description__text'>15 ETH</p>
          <p className='description__text'>55 ETH</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>BNB</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>9.00%</p>
          <p className='description__text'>75 BNB </p>
          <p className='description__text'>275 BNB</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>SOL</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>6.50%</p>
          <p className='description__text'>500 SOL</p>
          <p className='description__text'>10,000 SOL</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>LTC</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>7.50%</p>
          <p className='description__text'>300 LTC</p>
          <p className='description__text'>5000 LTC</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>ADA</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>7.75%</p>
          <p className='description__text'>100,000 ADA</p>
          <p className='description__text'>1,000,000 ADA</p>
        </div>
        <div className='table__description'>
          <p className='description__text'>XRP</p>
          <p className='description__text'> 365 days</p>
          <p className='description__text'>8.50%</p>
          <p className='description__text'>100,000 XRP</p>
          <p className='description__text'>1,000,000 XRP</p>
        </div>
      </div>
    </div>
  );
};
export default Table;
