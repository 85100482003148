import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';

import cW from './assets/cW.png';
import mM from './assets/mM.png';
import AppButton from './common/AppButton';
import { ActiveContext } from './App';

const ConnectModal = ({ setIsOpenMenu }) => {
  const {
    active,
    setActive,
    address,
    loadNFTs,
    walletconnect,
    loadWeb3,
    loadWeb3eth,
    bscaddress,
    ethaddress,
    bscaddressconnected,
    ethaddressconnected,
    bscWeb3,
    ethWeb3,
    connectWallet,
    show,
    showmeta,
    showwallet,
    setShow,
    account,
    nfts,
    walletvalue,
    walletethconnect,loadMyEth,getethData,loadMyBnb,getbnbData,walletethdisconnect,ethdisconnectbutton
        
  } = useContext(ActiveContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    setIsOpenMenu?.(false);
  };

  const handleconnect = async () => {
    if(bscaddressconnected == true){
       ethWeb3()
       loadMyEth()
       getethData()
    } 
    else{
      loadWeb3eth()
      
    }  
  }

  return (
    <>
     {ethdisconnectbutton?<div className='right__text' onClick={toggle}>
        {ethaddress}
      </div>:
      <div className='right__text' onClick={walletethdisconnect}>
        {ethaddress}
      </div>}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggle}
        onAfterOpen={() => (document.body.style.overflow = 'hidden')}
        onAfterClose={() => (document.body.style.overflow = 'unset')}
        overlayClassName='request-modal-overlay'
        className='request-modal'
      >
        <div class='connect-modal'>
          <button type='button' class='modal--metamask' onClick={loadWeb3eth}>
          {' '}
            <img className='w--180 ' src={mM} alt='' />
            Metamask
            <div className='text'>Connect to your MetaMask Wallet</div>
          </button>
          
          <button type='button' class='modal--metamask' onClick={walletethconnect}>
            {' '}
            <img className='w--90 h--90' src={cW} alt='' /> WalletConnect
            <div className='text'>Scan with WalletConnect to connect</div>
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ConnectModal;
