import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { ActiveContext } from '../../App';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Header = () => {
  const { ref, accountRef } = useContext(ActiveContext);
  const [isMobile, setMobile] = useState(false);
  const handleClick = () => {
    ref.current.scrollIntoView();
  };
  const handleClickAccount = () => {
    accountRef.current.scrollIntoView();
  };
  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 900)
      setMobile(true);
  }, [typeof window]);
  return (
    <>
      {isMobile ? (
        <MobileHeader
          handleClick={handleClick}
          handleClickAccount={handleClickAccount}
        />
      ) : (
        <DesktopHeader
          handleClick={handleClick}
          handleClickAccount={handleClickAccount}
        />
      )}
    </>
  );
};
export default Header;
