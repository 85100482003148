import Modal from 'react-modal';
import finalConditions from '../assets/conditions.pdf';
import Signature from './Signature';

const SignatureModal = ({
  isOpen,
  setIsOpen,
  setFile,
  setIsChecked,
  values,
  setValues,
  setSignatureCanvas,
  signatureCanvas,
}) => {
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsChecked(false);
          toggle();
        }}
        onAfterOpen={() => (document.body.style.overflow = 'hidden')}
        onAfterClose={() => (document.body.style.overflow = 'unset')}
        overlayClassName='signature-modal-overlay'
        className='signature-modal'
      >
        <Signature
          setFile={setFile}
          pdf={finalConditions}
          toggle={toggle}
          setSignatureCanvas={setSignatureCanvas}
          signatureCanvas={signatureCanvas}
          values={values}
          setValues={setValues}
        />
      </Modal>
    </>
  );
};

export default SignatureModal;
